import { PaginatedResponse } from "@interfaces/response/paginated-response.interface";
import { deleteRequest, getRequest, postRequest } from "api/base-services.request";
import { FavoritesEndpoints } from "shared/models/endpoits/clients/favorites/favorites.endpoints";
import { IRealestateResponseData } from "shared/models/interfaces/response/realestate.interface";

export function addFavorite(body: { realestate_id: number }) {
  return postRequest(FavoritesEndpoints.add, body).then(({ data }) => data.data);
}
export function getFavorites(params?: any) {
  return getRequest<PaginatedResponse<IRealestateResponseData[]>>(FavoritesEndpoints.list, params).then(({ data }) => data);
}
export function deleteFavorite(id: number | string) {
  return deleteRequest(FavoritesEndpoints.delete(id)).then(({ data }) => data.data);
}