
enum FavoritesStatic {
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Add favorite property.
   */
  add = "/v2/clients/favorites",
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description List favorite properties.
   */
  list = "/v2/clients/favorites",
}

const FavoritesDynamic = {
  /**
   * @member {string}
   * @method DELETE
   * @version 2.0
   * @description Delete favorite property.
   */
  delete: (id: number | string) => "/v2/clients/favorites/" + id,
};

export const FavoritesEndpoints = {
  ...FavoritesDynamic,
  ...FavoritesStatic,
};

export type FavoritesEndpoints = typeof FavoritesEndpoints;
