import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addFavorite,
  deleteFavorite,
} from "api/clients/favorites/favorites.request";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { CircularProgress, SvgIconProps, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "custom-hooks/redux.hooks";
import { openAuthDialog } from "store/feature/auth/auth-dialog.slice";
import useSnackBar from "custom-hooks/useSnackBar.hook";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { CPagesPath } from "shared/models/const/pages-path";
import { FavActionMadeFromList } from "shared/context/show-fav-action-snackbar.context";
import {
  useFavorites,
  useDeletedFavorites,
} from "shared/hooks/useFavorites.hook";
import useEventGa from "shared/hooks/useEventGa.hook";
import usePersistParams from "custom-hooks/usePersistParams.hook";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";
import dataLayerEvent from "custom-hooks/data-layer-event.hook";

interface IFavoritePropertyButton {
  size?: number;
  parent_id: number;
  is_favorite: boolean | null;
  borderColor?: SvgIconProps["color"];
  fontSize?: SvgIconProps["fontSize"];
  detail: any
}

export default function FavoritePropertyButton({
  parent_id,
  is_favorite,
  size = 34,
  borderColor,
  fontSize,
  detail
}: IFavoritePropertyButton) {
  const user = useAppSelector(({ Auth }) => Auth.user);
  const dispatch = useAppDispatch();
  const favoritesSet = useFavorites();
  const deletedFavoritesSet = useDeletedFavorites();
  const [isFavorite, setIsFavorite] = useState(
    !deletedFavoritesSet.has(parent_id) &&
    (!!is_favorite || favoritesSet.has(parent_id))
  );
  const queryClient = useQueryClient();
  const { showMessage } = useSnackBar();
  const favActionMadeFromList = useContext(FavActionMadeFromList) ?? true;
  const { event } = dataLayerEvent();
  const refererValue: any = useGetPersistParams({ onlyCode: true });

  const detail_event = {
    codigo: detail?.parent_id,
    nombre: detail?.slug,
    sector: detail?.sector,
    city: detail?.city,
    tipo_propiedad: detail?.typePropertyLabel,
    precio: detail?.price,
    agent: detail?.agent_list?.map((agent: any) => agent.name),
    isAgencia: refererValue?.areferer ? true : false,
    isAgente: refererValue?.referer ? true : false,
    codigo_referido: refererValue?.areferer ?? refererValue?.referer ?? null,
  };

  const { mutate: addFav, isLoading: loadingAdd } = useMutation(addFavorite, {
    onSuccess: () => {
      favoritesSet.add(parent_id);
      deletedFavoritesSet.delete(parent_id);
      setIsFavorite(true);
      showMessage(<Message action="saved" withLink={favActionMadeFromList} />, {
        variant: "success",
      });
      queryClient.invalidateQueries(["favorites"]);
    },
  });

  const { mutate: removeFav, isLoading: loadingRemove } = useMutation(
    deleteFavorite,
    {
      onSuccess: () => {
        favoritesSet.delete(parent_id);
        deletedFavoritesSet.add(parent_id);
        setIsFavorite(false);
        showMessage(
          <Message action="removed" withLink={favActionMadeFromList} />,
          { variant: "default" }
        );
      },
    }
  );

  const handleFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!user) return dispatch(openAuthDialog("signin"));

    if (!loadingAdd && !loadingRemove) {
      !isFavorite ? addedFavorite(parent_id) : removeFavorite(parent_id);
    }
  };
  const addedFavorite = (parent_id: any) => {
    addFav({ realestate_id: parent_id });
    event(
      {
        event_name: "add-favorite",
        body: {
          ...detail_event,
        }
      }
    );
  };
  const removeFavorite = (parent_id: number) => {
    removeFav(parent_id);
    event({
      event_name: "remove-favorite",
      body: {
        ...detail_event,
      },
    });
  };

  useEffect(() => {
    if (is_favorite && !deletedFavoritesSet.has(parent_id)) {
      favoritesSet.add(parent_id);
    }
  }, []);

  useEffect(() => {
    setIsFavorite(
      !deletedFavoritesSet.has(parent_id) &&
      (!!is_favorite || favoritesSet.has(parent_id))
    );
  }, [is_favorite]);

  const FavButton = styled.button`
  width: ${size}px;
  height: ${size}px;
  clip-path: circle();
  border: none;
  transition: transform 0.5s ease-in-out;
  color: var(--color-dark-gray);
  background-color: transparent;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
  }
`;


  return (
    <Tooltip title={!isFavorite ? "Agregar a favoritos" : "Quitar de favoritos"}>
      <div>
        <FavButton onClick={handleFavorite}>
          <Container>
            {loadingAdd || loadingRemove ? (
              <CircularProgress
                className="loading"
                color="inherit"
                thickness={2}
                size={size - 4}
              />
            ) : (
              isFavorite ? (
                <FavoriteIcon fontSize={fontSize} color="error" />
              ) : (
                <FavoriteBorderIcon fontSize={fontSize} color={borderColor} />
              )
            )}
          </Container>
        </FavButton>
      </div>

    </Tooltip>
  );
}

const Message = ({
  action,
  withLink,
}: {
  action: "saved" | "removed";
  withLink: boolean;
}) => {
  const t = useTranslations("Favorites");

  return (
    <SuccessSnackBody>
      {t(action)}&nbsp;
      {withLink && (
        <Link href={CPagesPath.es.profileFavorites}>{t("link")}</Link>
      )}
    </SuccessSnackBody>
  );
};

const Container = styled.div`
  position: relative;
  padding-top: 2px;

  .MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loading {
    opacity: 0.5;
  }
`;

const SuccessSnackBody = styled.div`
  a {
    color: white;
    font-weight: bold;

    :hover {
      text-decoration: underline;
    }
  }
`;